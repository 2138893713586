<script>
  import { pluralize } from '@qlean/web-core'
  import { SubHeader, Text, Picture, Separator, Icon } from '@qlean/ui-kit-web-svelte'

  export let cN
  export let photos
  export let name
  export let rating
  export let servicesAmount
  export let serviceNames
</script>

<div class={cN('persons__person')}>
  <Picture type={Picture.PROPORTIONS.EXTRA_WIDE} fitType={Picture.FIT_TYPES.COVER} {...photos} />
  <Separator key={15} size={Separator.SIZES.SMALL} />
  <div class={cN('persons__person-content-container')}>
    <Separator key={15} size={Separator.SIZES.SMALL} direction={Separator.DIRECTIONS.LEFT_RIGHT} />
    <div class={cN('persons__person-content')}>
      <SubHeader size={SubHeader.SIZES.STRONG}>{name}</SubHeader>
      <Separator key={5} size={Separator.SIZES.SMALL} />
      <div class={cN('persons__stats')}>
        <Icon name={Icon.NAMES.star} key={Icon.KEYS.SMALL} class={cN('persons__star')} />
        <Text>
          {rating}&thinsp;∙&thinsp;{`${servicesAmount} ${pluralize(serviceNames, servicesAmount)}`}
        </Text>
      </div>
    </div>
    <Separator key={15} size={Separator.SIZES.SMALL} direction={Separator.DIRECTIONS.LEFT_RIGHT} />
  </div>
  <Separator key={15} size={Separator.SIZES.SMALL} />
</div>

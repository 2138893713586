<script>
  import classNames from 'classnames/bind'
  import {
    GridContainer,
    Icon,
    WidgetHeader,
    WidgetFooter,
    Separator,
  } from '@qlean/ui-kit-web-svelte'

  import Card from './Card'

  import css from './_styles'

  const cN = classNames.bind(css)

  export let title
  export let subTitle = undefined
  export let withTopMargin
  export let withBottomMargin
  export let list
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {subTitle} withMargin={withTopMargin} />

<div class={cN('persons')}>
  <GridContainer class={cN('persons__content')}>
    {#each list as { photos, name, rating, servicesAmount }, idx}
      <Card {...$$restProps} {photos} {name} {rating} {servicesAmount} {cN} />
      <Separator
        key={15}
        size={Separator.SIZES.SMALL}
        direction={Separator.DIRECTIONS.LEFT_RIGHT} />
    {/each}
  </GridContainer>
</div>

<WidgetFooter withMargin={withBottomMargin} />
